.signature {
    padding: 0 40px;
}

.signature a {
    color: inherit;
    font-weight: 500;
    transition: color .2s;
}
.signature a:hover {
    color: var(--primary);
    text-decoration: underline;
}

@media screen and (max-width: 600px) {
    .signature {
        text-align: center;
    }
}

@media screen and (min-width: 600px) and (max-width: 900px) {
    .signature {
        position: absolute;
        padding: 0;
        right: 32px;
        bottom: 32px;
        text-align: right;
    }
}