.about {
    box-sizing: border-box;
    min-height: 90vh;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 40px;
    /* background-color: var(--neutral); */
}
@media screen and (max-width: 900px) {
  .about {
    padding: 80px 32px;
    align-items: stretch;
    /* height: auto; */
    box-shadow: 0 0 64px 32px var(--neutral-dark) inset; 
  }
}
@media screen and (min-width: 900px) {
  .about {
    /* padding-top: 240px; */
    box-shadow: 0 0 64px 72px var(--neutral-dark) inset; 
  }
}
@media screen and (min-width: 900px) {
  .about {
    /* padding-top: 240px; */
    box-shadow: 0 0 64px 72px var(--neutral-dark) inset; 
  }
}

.about > .background {
  z-index: -1;
  position: absolute;
}

@media screen and (max-width: 600px) {
  .about > .background {
    top: 0px;
    right: 0px;
    width: 100%;
    height: 100%;
  }
  .about > .background > img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}
@media screen and (min-width: 600px) {
  .about > .background {
    mix-blend-mode: lighten;
    width:80%;
    opacity: .5;
    /* top: -150px; */
    right: -150px;
  }
  .about > .background > img {
    width:100%;
  }
}

@media screen and (min-width: 900px) {
  .about > .background {
    top: -150px;
  }
  .about p {
    width: 600px;
  }
}