
@media screen and (min-width: 900px) {
    .performances .wrapper {
        position: relative;
        display: flex;
        justify-content: center;
        margin-top: 16px;
        width: 640px;
        min-height: 540px;
        margin-bottom: 64px;
    }
    .performances .wrapper > div {
        position: absolute;
        width: 640px;
        transition: all 1s ease-in-out;
    }
    
    .performance {
        display: flex;
        flex-direction: column;
        align-items: center;
        transform: scale(80%);
        transition: transform 1s;
        transition-timing-function: ease-in-out;
    }
    .performance > img {
        border: 5px solid var(--neutral-50);
        opacity: .4;
        filter: grayscale(50%);
        width: 640px;
        height: 540px;
        object-fit: cover;
        box-shadow: 0 4px 4px var(--neutral-dark);
        transition: filter .5s, border-color .5s, opacity .5s, box-shadow .5s;
    }
    
    .performance.prev,
    .performance.next {
        cursor: pointer;
    }
    
    .performance.prev::after,
    .performance.next::after {
        position: absolute;
        height:100%;
        display: flex;
        align-items:center;
        font-weight: 400;
        font-family: 'FontAwesome';
        font-size: 96px;
        color: var(--primary);
        text-shadow:
            -2px -2px 2px var(--neutral-dark),
             2px -2px 2px var(--neutral-dark),
            -2px  2px 2px var(--neutral-dark),
             2px  2px 2px var(--neutral-dark);
    }
    
    .performance.prev::after {
        content: '\f053';
    }
    .performance.next::after {
        content: '\f054';
    }
    
    .performance:hover {
        transform: scale(85%);
    }
    .performance:hover > img {
        border: 5px solid var(--neutral-light-50);
        opacity: .5;
        box-shadow: 0 8px 8px var(--neutral-dark);
        filter: grayscale(20%);
    }
    
    .performance:hover.prev::after,
    .performance:hover.next::after {
        color: var(--primary);
    }
    
    .performance.selected {
        transform: scale(1);
    }
    .performance.selected > img {
        border: 5px solid var(--neutral-light-50);
        opacity: 1;
        box-shadow: 0 16px 16px var(--neutral-dark);
        filter: grayscale(0);
    }
    .performance > .title {
        width: 100%;
        text-align: center;
        position: absolute;
        left: 0;
        bottom: -64px;
        height: 40px;
        transition: color .5s;
    }
    .performance:not(.selected) > .title {
        color: var(--neutral-light-50);
    }
    
    /** DESCRIPTION */
    .performances .description {
        display: flex;
        justify-content: center;
        position: relative;
        height: 92px;
        width: 100%;
    }
    .performances .description > p {
        position: absolute;
        top: 0;
        text-align: center;
        opacity: 0;
        transition: opacity .5s;
    }
    .performances .description > p.selected {
        opacity: 1;
    }
}