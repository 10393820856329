.social-links {
    display: flex;
    list-style-type: none;
    padding: 0;
    margin: 0;
    margin-left: -16px;
}
.social-links > li > a {
    font-family: 'FontAwesome Brands';
    text-decoration: none;
    color: var(--contrast);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 36px;
    width: 64px;
    height: 64px;
    border-radius: var(--radius-l);
    transition: color .3s, box-shadow .3s;
}
.social-links > li > a:hover {
    color: var(--primary);
}
.social-links > li > a:focus,
.social-links > li > a:focus-within,
.social-links > li > a:focus-visible {
    outline: 0;
    color: var(--primary);
    box-shadow: 0px 0px 8px 0px var(--primary) !important;
}

@media screen and (max-width: 900px) {
    .social-links {
        justify-content: center;
        gap: 8px;
        margin: 0;
    }
    .social-links > li > a {
        border: 2px solid var(--neutral-light-50);
    }
    .social-links > li > a:hover {
        color: var(--primary);
    }
    .social-links > li > a:focus,
    .social-links > li > a:focus-within,
    .social-links > li > a:focus-visible {
        outline: 0;
        color: var(--primary);
        box-shadow: 0px 0px 8px 0px var(--primary) !important;
    }
}