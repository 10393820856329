html:has(.loading-page:not(.is-loaded)) {
  overflow-y: hidden !important;
}

.loading-page {
  z-index: 1;
  position: fixed;
  inset: 0;

  background-color: var(--neutral-dark);

  height: 100lvh;
  width: 100lvw;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 40px;

  --card-size: min(80vw, 50vh);
  perspective: calc(var(--card-size) * 3);

  transition: opacity 1s ease-out, transform 1s ease-in;
}

.loading-page.is-loaded {
  opacity: 0;
  transform: scale(3);
}

.loading-page:not(.show-overlay) {
  display: none;
}

.loading-page p {
  font-size: 12px;
}

.loading-page.is-loaded p {
  opacity: 0;
}

.loading-page .card-flip {
  position: relative;
  height: var(--card-size);
  aspect-ratio: 0.7;
  border-radius: 10px;
  border: 1px solid black;
  transform-style: preserve-3d;
  transform-origin: center center;
  animation: cardRotate 5s linear infinite;
}

.loading-page .card-flip .recto,
.loading-page .verso {
  position: absolute;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border-radius: 10px;
}

.loading-page .card-flip .recto {
  z-index: 1;
  background: linear-gradient(#fff, #e7eff0);
  /* background-color: #e7eff0; */
}
.loading-page .card-flip .recto img.big-spade {
  height: calc(var(--card-size) / 6);
}


.loading-page .verso {
  z-index: -1;
  transform: translateZ(1px);
  background: linear-gradient(var(--primary), var(--primary-dark));
  border: calc(var(--card-size) / 30) solid #f9f9f9;
  animation: showVerso 5s linear infinite;
}

.loading-page .card-flip .logo-md {
  height: calc(var(--card-size) / 8);
  opacity: .9;
  width: auto;
}

@keyframes cardRotate {
  0%    {transform: rotateX(0)      rotateY(0)        rotateZ(0deg);}
  100%  {transform: rotateX(360deg) rotateY(1080deg)  rotateZ(0deg);}
}

@keyframes showVerso {
  0%      {z-index: 1}
  16.666% {z-index: 0}
  33.333% {z-index: 1}
  50%     {z-index: 0}
  66.666% {z-index: 1}
  83.333% {z-index: 0}
  100%    {z-index: 1}
}

.pip {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #000;
  font-family: "Bitter";
  font-size: calc(var(--card-size) / 10);
  line-height: calc(var(--card-size) / 10);
  font-weight: bold;
}

.pip > img {
  height: calc(var(--card-size) / 12);
  width: auto;
}

.pip.pip-top {
  top: 10px;
  left: 10px;
}

.pip.pip-bottom {
  transform: rotateX(180deg);
  bottom: 10px;
  right: 10px;
}