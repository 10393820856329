.button {
    display: flex;
    justify-content: center;
    gap: 8px;
    box-sizing: border-box;
    padding: 16px;
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
    border-radius: var(--radius-m);
    background-color: var(--primary);
    border: 2px solid var(--primary);
    color: var(--neutral-dark);
    box-shadow: 0 0 4px 0 rgba(0 0 0 / 50%);
    transition: background-color .3s, border-color .3s;
    user-select: none;
    white-space: nowrap;
    text-decoration: none;
}
@media screen and (max-width: 600px) {
    .button {
        width: 100%;
    }
}
@media screen and (max-width: 900px) {
    .button {
        font-size: 16px;
    }
}
.button:hover {
    background-color: var(--primary-dark);
    border-color: var(--primary-dark);
    cursor:pointer;
}

.button.contrast {
    background-color: var(--contrast);
    border-color: var(--contrast);
    color: var(--neutral-dark);
}
.button.contrast:hover {
    background-color: var(--neutral-light);
    border-color: var(--neutral-light);
}

.button.outline {
    color: var(--primary);
    background-color: rgba(0 0 0 / 0%);
    border: 2px solid var(--primary);
    text-shadow: 
        1px 0px 2px #00000070,
        0px 1px 2px #00000070,
        -1px 0px 2px #00000070, 
        0px -1px 2px #00000070;
    box-shadow:
        0 0 4px 0 rgba(0 0 0 / 50%),
        0 0 4px 0 rgba(0 0 0 / 50%) inset;
    transition: background-color .3s, color .3s, border-color .3s, text-shadow .3s, box-shadow .3s;
}
.button.outline:hover {
    text-shadow:
        1px 0px 2px #00000000,
        0px 1px 2px #00000000,
        -1px 0px 2px #00000000, 
        0px -1px 2px #00000000;
    box-shadow:
        0 0 4px 0 rgba(0 0 0 / 50%),
        0 0 4px 0 rgba(0 0 0 / 0%) inset;
    color: var(--neutral-dark);
    background-color: var(--primary-dark);
    border-color: var(--primary-dark);
}

.button.outline.contrast {
    color: var(--contrast);
    border-color: var(--contrast);
}
.button.outline.contrast:hover {
    color: var(--neutral-dark);
    background-color: var(--neutral-light);
    border-color: var(--neutral-light);
}

.button:focus,
.button:focus-within,
.button:focus-visible {
    outline: 0;
    box-shadow: 0px 0px 0px 2px #000000, 0px 0px 8px 2px var(--primary-dark) !important;
}