/* @import url('https://fonts.googleapis.com/css2?family=Bitter:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Noto+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap'); */

@font-face {
  font-family: "Bitter";
  src: url("../public/fonts/Bitter/Bitter-VariableFont_wght.ttf") format("truetype-variations");
}

@font-face {
  font-family: "Noto Sans";
  font-weight: 400;
  src: url("../public/fonts/Noto Sans/NotoSans-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Noto Sans";
  font-weight: 500;
  src: url("../public/fonts/Noto Sans/NotoSans-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Noto Sans";
  font-weight: 600;
  src: url("../public/fonts/Noto Sans/NotoSans-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Noto Sans";
  font-weight: 700;
  src: url("../public/fonts/Noto Sans/NotoSans-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "FontAwesome";
  font-weight: 400;
  src: url("../public/fonts/FontAwesome/Font Awesome 6 Sharp-Regular-400.otf") format("opentype");
}

@font-face {
  font-family: "FontAwesome";
  font-weight: 900;
  src: url("../public/fonts/FontAwesome/Font Awesome 6 Sharp-Solid-900.otf") format("opentype");
}

@font-face {
  font-family: "FontAwesome Brands";
  src: url("../public/fonts/FontAwesome/Font Awesome 6 Brands-Regular-400.otf") format("opentype");
}

.fa {
  font-family: "FontAwesome";
  font-weight: 400;
}

.fas {
  font-family: "FontAwesome";
  font-weight: 900;
}

html, body {
  max-width: 100vw;
  overflow-x: hidden;
}

body {
  font-family: "Noto sans", sans-serif;
  font-size: 16px;

  /* VARIABLES */
  --contrast: #fff;

  --primary-light: #F9D2AF;
  --primary: #F39B4A;
  --primary-dark: #E87432;

  --neutral-light: #C1D8E8;
  --neutral-light-50: #C1D8E860;
  --neutral-light-5: #C1D8E812;
  --neutral: #0C191E;
  --neutral-50: #0C191E60;
  --neutral-dark: #080F10;

  --radius-m: 2px;
  --radius-l: 8px;

  background-color: var(--neutral-dark);
  color: var(--neutral-light);
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Bitter", sans-serif;
  color: var(--contrast);
  margin: 0;
}

h1 {
  font-size: 92px;
  font-weight: 900;
  text-transform: uppercase;
  text-shadow: 2px 0px 1px #000000, 0px 2px 1px #000000, -2px 0px 1px #000000, 0px -2px 1px #000000, 0px 0px 16px #00000088;
}

h2 {
  font-size: 40px;
  font-weight: 700;
}

h3 {
  font-size: 32px;
  font-weight: 600;
}

strong {
  color: var(--primary);
  font-weight: unset;
}

p > strong {
  color: var(--contrast);
  font-weight: bold;
}

section {
  position: relative;
  overflow: hidden;
  height: 95vh;
}

::selection {
  color: var(--primary);
  background-color: var(--neutral);
}

::-moz-selection {
  color: var(--primary);
  background-color: var(--neutral);
}

.icon {
  font-family: 'FontAwesome';
  font-style: normal;
}

@media screen and (max-width: 900px) {
  section {
    padding: 32px;
  }
  h1 {
    font-size: 40px;
    text-align: center;
  }
  h2 {
    font-size: 24px;
    text-align: center;
  }
  h3 {
    font-size: 22px;
    text-align: center;
  }
}

/** TABLET */
@media screen and (min-width: 600px) and (max-width: 900px) {
  section {
    padding: 32px;
  }
  h1 {
    font-size: 64px;
  }
  h2 {
    font-size: 32px;
  }
  h3 {
    font-size: 28px;
  }
}

/** MEDIUM SCREEN */
@media screen and (min-width: 900px) {
  section {
    padding: 32px 40px;
  }
}

/** LARGE SCREEN */
@media screen and (min-width: 1200px) {
  section {
    padding: 40px 120px;
  }
}

/** EXTRALARGE SCREEN */
@media screen and (min-width: 1600px) {
  section {
    padding: 40px 240px;
  }
}