@keyframes fadeIn {
    from {opacity: 0;}
    to {opacity: 1;}
}

.landing {
    box-sizing: border-box;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    box-shadow: 0 -64px 48px var(--neutral-dark) inset; 
}
@media screen and (max-width: 900px) {
    .landing {
        height: 90vh;
    }
}
@media screen and (min-width: 900px) {
    .landing {
        padding: 10vh 0;
    }
}

.landing .background {
    z-index: -1;
    position: absolute;
    mix-blend-mode: lighten;
    opacity: 0;
    animation: fadeIn 2s ease-out forwards;
}

/** MOBILE + TABLET */
@media screen and (max-width: 900px) {
    .landing .background {
        top: 0;
    }
    .landing .background.mask > img {
        height: 90vh;
    }
}
@media screen and (min-width: 900px) and (max-width: 1200px) {
    .landing .background {
        top: -20px;
    }
    .landing .background.mask > img {
        width: 100vw;
    }
}

/** LARGE+ */
@media screen and (min-width: 1200px) {
    .landing .background {
        top: -150px;
    }
}

.landing h1 {
    opacity: 0;
    animation: fadeIn 1s 2s ease-out forwards;
}
.landing .subtitle-wrapper {
    opacity: 0;
    animation: fadeIn 1s 2.5s ease-out forwards;
}
.landing .actions {
    opacity: 0;
    animation: fadeIn 1s 3.5s ease-out forwards;
}

.subtitle-wrapper {
    display: flex;
    align-items: center;
    gap: 24px;
}

.subtitle-wrapper > .subtitle {
    font-size: 32px;
    font-weight: 600;
    font-size: 21px;
    line-height: 100%;
    color: var(--primary);
    letter-spacing: 0.3em;
    text-align: center;
    text-shadow: 2px 0px 1px #000000, 0px 2px 1px #000000, -2px 0px 1px #000000, 0px -2px 1px #000000;
}

.landing .actions {
    margin-top: 40px;
    display:flex;
    gap: 16px;
}
@media screen and (max-width: 600px) {
    .landing .actions {
        width: 100%;
        flex-direction: column;
        align-items: stretch;
    }
}

/** MOBILE */
@media screen and (max-width: 600px) {
    .subtitle-wrapper {
        gap: 16px;
    }
    .subtitle-wrapper > .subtitle {
        font-size: 16px;
        color: var(--primary-light);
        letter-spacing: 0;
        margin-top: 8px;
    }
    .subtitle-wrapper > img {
        display: none;
    }
}
/** TABLET */
@media screen and (min-width: 600px) and (max-width: 900px) {
    .subtitle-wrapper {
        gap: 24px;
    }
    .subtitle-wrapper > .subtitle {
        font-size: 24px;
        color: var(--primary-light);
        letter-spacing: .04em;
        margin-top: 8px;
    }
    .subtitle-wrapper > img {
        display: none;
    }
}