.performances {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;
    box-shadow: 0 0 48px 32px var(--neutral-dark) inset; 
}

@media screen and (min-width: 900px) {
    .performances {
        position: relative;
        padding: 80px 0;
        background: transparent;
    }
}

.performances .background {
    z-index: -1;
    mix-blend-mode: lighten;
    position: absolute;
    opacity: .4;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.performances .background > img {
    z-index: 0;
    position: absolute;
    top: 0;
    /* filter: blur(16px); */
    opacity: 0;
    transition: opacity .4s;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.performances .background > img.selected {
    opacity: .8;
}

@media screen and (max-width: 900px) {
    .performances .background {
        opacity: .2;
    }
}