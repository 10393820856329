.demo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 32px;
    background-color: var(--neutral);
    height: auto;
}
@media screen and (max-width: 900px) {
    .demo {
        padding: 48px 16px 24px;
    }
}

.demo > .video {
    flex-basis: 0;
    background: #000000;
    border: 2px solid var(--neutral-light-50);
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.5);
    border-radius: var(--radius-l);
    /* à changer */
    width: 100%;
    position: relative;
    padding-bottom: 56.25%;
    overflow: hidden;
}

.demo > .video > iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}