.performance {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.performance > img {
    border: 5px solid var(--neutral-50);
    box-shadow: 0 4px 4px var(--neutral-dark);
}

.performance > .title {
    width: 100%;
    text-align: center;
    height: 40px;
}

@media screen and (max-width: 900px) {
    .performance {
        padding: 48px 0;
        gap: 40px;
    }
    .performance img {
        width: 100%;
        border: 2px solid var(--neutral-light-50);
        border-radius: var(--radius-l);
    }
    .performance .title-wrapper {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 16px;
    }
    .performance .title-wrapper > .stroke {
        flex: 1 0;
        height: 2px;
        border-radius: 2px;
        background-color: var(--neutral-light-50);
    }
    .performance .title-wrapper > .dot {
        height: 2px;
        width: 2px;
        border-radius: 2px;
        background-color: var(--neutral-light-50);
    }
    .performance p {
        margin: 0;
        text-align:center;
    }
}