.testimonials {
    display: flex;
    gap: 40px;
    padding-bottom: 80px;
    flex-direction: column;
    align-items: center;
    height: auto;
    background-color: var(--neutral);
}

.testimonials > .cards {
    display: flex;
    align-items: stretch;
    gap: 24px;
}
@media screen and (max-width: 900px) {
    .testimonials {
        padding: 40px 16px;
    }
    .testimonials > .cards {
        flex-direction: column;
    }
}

.testimonials > .cards > .card {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 32px 24px;
    gap: 24px;
    flex: 1 0;
    background-color: var(--neutral-light-5);
    border-radius: var(--radius-l);
}

.testimonials > .cards > .card > .quote {
    font-family: 'FontAwesome';
    font-size: 20px;
    color: var(--primary);
    font-weight: 900;
}
.testimonials > .cards > .card > p {
    flex: 1 0;
    line-height: 24px;
    text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.5);
}
.testimonials > .cards > .card .author {
    line-height: 100%;
    color: var(--contrast);
    font-weight: 500;
    margin-bottom: 8px;
}
.testimonials > .cards > .card .from {
    line-height: 100%;
    color: var(--neutral-light-50);
}

/** LOGOS */

.testimonials .logos {
    height: 106px;
    --carousel-width: 2400px;
}
.testimonials .logos > .logo-carousel {
    position: absolute;
    left: 0;
    width: var(--carousel-width);
    display: flex;
    align-items: center;
}
.testimonials .logos .list {
    /* position: absolute; */
    min-width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.testimonials .logos .list > img {
    height: 72px;
    margin-right: 24px;
}

@keyframes autoslide {
    0% {transform: translateX(0);}
    100% {transform: translateX(calc(-1 *var(--carousel-width)));}
}

.testimonials .logos > .logo-carousel {
    animation: autoslide 60s infinite linear;
}
.testimonials .logos > .logo-carousel.reverse {
    animation-direction: reverse;
}
.testimonials .logos.mobile {
    display: none;
}
.testimonials .logos .list > img {
    height: 72px;
    margin-right: 16px;
    width: 0;
    flex-grow: 1;
    object-fit: contain;
    background-color: rgba(0 0 0 / 15%);
    border-radius: var(--radius-l);
    border: 1px solid var(--neutral-dark);
    padding: 16px 8px;
    transition: background-color .1s;
}
.testimonials .logos .list > img:hover {
    background-color: rgba(0 0 0 / 25%);
}

@media screen and (max-width: 899px) {
    .testimonials .logos {
        --carousel-width: 1200px;
    }
    .testimonials .logos:not(.mobile) {
        display: none;
    }
    .testimonials .logos.mobile {
        display: block;
        margin-top: 16px;
    }
}

@media screen and (max-width: 600px) {
    .testimonials .logos {
        --carousel-width: 900px;
    }
    .testimonials > .cards > .card > p {
        margin: 0;
    }
    .testimonials .logos > .logo-carousel {
        animation-duration: 30s;
    }
    .testimonials .logos img {
        height: 40px;
    }
}