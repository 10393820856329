.contact {
    box-sizing: border-box;
    min-height: 100vh;
    height: auto;
    display: flex;
    gap: 40px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--neutral-dark);
}

.contact > .wrapper {
    position: relative;
    display: flex;
    gap: 56px;
    /* width: 100%; */
    background-color: var(--neutral);
    border-radius: var(--radius-l);
    padding: 56px;
}

.contact .card {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 20px;
    /* padding: 40px; */
}
.contact .card > p {
    margin: 0;
}
.contact .card a.contact-link {
    display: block;
    font-size: 16px;
    font-weight: bold;
    color: var(--primary);
    text-decoration: none;
    border-radius: 2px;
    transition: color .15s ease-out;
    white-space:nowrap;
    margin-bottom: 20px;
}
.contact .card a.contact-link:hover {
    color: var(--primary-dark);
}
.contact .card a.contact-link .icon {
    margin-right: 16px
}

.contact .card.none {
    border: 0;
    background: none;
    margin-top: 56px;
}

/* @media screen and (min-width: 900px) {
    .contact .card.outline {
        flex-grow: 0;
        border: 2px solid var(--neutral-light-50);
        background: none;
        margin-bottom: 40px;
    }
} */
.contact form {
    display: flex;
    flex-direction: column;
    gap: 32px;
}
.contact form > .input-group {
    display:flex;
    flex-direction: column;
    gap: 12px;
}
.contact form > .input-group label {
    color: var(--contrast);
    align-self: flex-start;
    font-size: 14px;
    line-height: 100%;
    font-weight: 500;
    letter-spacing: 0.05em;
    cursor: pointer;
}

.contact form > .input-group > input,
.contact form > .input-group > textarea {
    flex-grow: 1;
    display: block;
    border: 2px solid var(--neutral-light-50);
    background: none;
    padding: 12px 16px;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: var(--contrast);
    font-family: 'Noto Sans';
    border-radius: var(--radius-m);
    transition: border-color .3s;
}
.contact form > .input-group > input.error,
.contact form > .input-group > textarea.error {
    color: var(--primary-dark);
}
.contact form small.error-message {
    color: var(--primary);
}

.contact form > .input-group > textarea {
    resize: vertical;
}

.contact form > .input-group > input::placeholder,
.contact form > .input-group > textarea::placeholder {
    color: var(--neutral-light-50);

}
.contact form > .input-group > input:hover,
.contact form > .input-group > textarea:hover {
    border-color: var(--neutral-light);
}
.contact form > .input-group > input:focus,
.contact form > .input-group > textarea:focus,
.contact form > .input-group > input:focus-visible,
.contact form > .input-group > textarea:focus-visible {
    border-color: var(--primary);
    outline: 0;
}

.contact form > .button {
    align-self: flex-start;
    padding-left: 40px;
    padding-right: 40px;
}

/* Formats de prestations */

.contact ul.formats {
    list-style-type: none;
    margin: 0;
    padding: 0;
}
.contact ul.formats > li {
    color: var(--neutral-light);
    font-size: 20px;
    padding: 8px 0;
}
.contact ul.formats > li::before {
    font-family: "FontAwesome";
    content: '\f2f4';
    margin-right: 16px;
}

@media screen and (max-width: 900px) {
    .contact {
        height: auto;
        gap: 32px;
        padding: 32px 16px 16px;
    }
    .contact > .wrapper {
        flex-direction: column;
        gap: 24px;
        padding: 0;
        background-color: rgba(0 0 0 / 0%);
    }
    .contact .form-card h3 {
        text-align: left;
    }
    .contact form {
        gap: 16px;
    }
    .contact .card {
        padding: 32px 24px;
        gap: 24px;
        border: 2px solid var(--neutral-light-5);
        background-color: var(--neutral);
    }
    .contact .contact-details {
        text-align: center;
    }
}