body {
    background-color: var(--neutral);
}

.message-sent {
    height: auto;
    box-sizing: border-box;
}

.message-sent .card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    background-color: var(--neutral-light-5);
    padding: 32px;
    border-radius: var(--radius-l);
}
.message-sent .card > hr {
    border: 1px solid var(--neutral-light-50);
    width: 100%;
}

.message-sent .card .envelope {
    font-family: "FontAwesome";
    font-size: 40px;
    color: var(--primary);
}

.message-sent .wrapper {
    margin-top: 32px;
    padding: 32px;
}

.message-sent .wrapper h3 {
    margin-bottom: 24px;
}

@media screen and (max-width: 900px) {
    .message-sent {
        padding: 16px;
            
    }
    .message-sent .card {
        padding: 40px 32px;
        align-items: center;
    }
    .message-sent .card p {
        text-align: center;
    }
    .message-sent .card hr {
        display: none;
    }
}

@media screen and (min-width: 1200px) {
    .message-sent .card,
    .message-sent .wrapper {
        padding: 48px;
    }
}